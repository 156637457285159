<template>
    <el-select popper-class="period-select" v-model="periodIndex" placeholder="Periodväljare" @change="periodSelectChanged" @visible-change="showDatePickerModal" :disabled="disabled">
        <el-option-group>
            <el-option v-for="period in yearlyPeriod" :key="period.localId" :label="period.name" :value="period.localId" />
        </el-option-group>
        <el-option-group>
            <el-option v-for="period in quarterPeriod" :key="period.localId" :label="period.name" :value="period.localId" />
        </el-option-group>
        <el-option-group>
            <el-option label="Anpassat datum" value="calendar" />
        </el-option-group>
    </el-select>
</template>
<script>
import queryString from "query-string";
import moment from "moment";

export default {
    props: {
        query: {
            type: Object,
            default: () => {},
        },
        periodData: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        periodDataComputed() {
            return this.periodData.map((item, index) => {
                item.localId = index;
                return item;
            });
        },

        yearlyPeriod() {
            return this.periodDataComputed.filter(item => item.group === "Year");
        },

        quarterPeriod() {
            return this.periodDataComputed.filter(item => item.group === "Quarter");
        },
    },

    watch: {
        query(newValue, oldValue) {
            if (newValue.startDate === oldValue.startDate) return;
            if (newValue.endDate === oldValue.endDate) return;
            if (!this.periodData.length) return;

            const { startDate, endDate } = queryString.parse(location.search);
            const existingDate = this.periodDataComputed.filter(item => {
                return moment(item.startDate).isSame(startDate) && moment(item.endDate).isSame(endDate);
            });

            if (existingDate.length === 1) {
                this.periodIndex = existingDate[0].localId;
            } else {
                this.periodIndex = "calendar";
            }
        },

        periodDataComputed() {
            if (!this.periodIndex && this.periodData.length) {
                // this.periodIndex = 0;
                this.periodSelectChanged();
            }
        },

        periodData() {
            if (!this.periodData.length) return;
            const { startDate, endDate } = queryString.parse(location.search);

            const existingDate = this.periodDataComputed.filter(item => {
                return moment(item.startDate).isSame(startDate) && moment(item.endDate).isSame(endDate);
            });

            if (existingDate.length === 1) {
                this.periodIndex = existingDate[0].localId;
            } else {
                this.periodIndex = "calendar";
            }

            if (!startDate) {
                this.periodIndex = 0;
                const query = queryString.parse(location.search);
                query.startDate = moment(this.periodDataComputed[0].startDate).format("YYYY-MM-DD");
                query.endDate = moment(this.periodDataComputed[0].endDate).format("YYYY-MM-DD");
                this.$router.replace({ query }).catch(() => {});
            }
        },

        customDate(value) {
            if (value) {
                this.periodIndex = "calendar";
            }
        },
    },

    data() {
        return {
            periodIndex: null,
            selectedPeriod: null,
        };
    },

    methods: {
        async periodSelectChanged() {
            if (this.periodIndex === "calendar") {
                this.showDatePickerModal();
                return;
            }
            this.selectedPeriod = this.periodDataComputed.filter(item => item.localId === this.periodIndex)[0];

            setTimeout(() => {
                this.$emit("dateSelectionChanged");
            }, 1000);

            if (!this.selectedPeriod) return;

            const query = queryString.parse(location.search);
            query.startDate = moment(this.selectedPeriod.startDate).format("YYYY-MM-DD");
            query.endDate = moment(this.selectedPeriod.endDate).format("YYYY-MM-DD");
            await this.$router.replace({ query }).catch(() => {});
            this.$emit("dateSelectionChanged");
        },

        showDatePickerModal(event) {
            if (event === false && this.periodIndex === "calendar") {
                this.$emit("showDateRangePickerModal");
            }
        },

        resetState() {
            this.periodIndex = null;
        },

        setCustomCalendarValue() {
            this.periodIndex = "calendar";
        },
    },
};
</script>
<style>
.period-select .el-select-dropdown__wrap {
    max-height: 700px !important;
}
</style>
